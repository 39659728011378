<template>
  <div class="check-media">
    <AssociationScroll :showTabs="false">
      <AssociationScrollView title="现场查勘"
        name="0"
        v-if="auth('surveyorPhone','e')">
        <div class="surveyor-content">
          <el-switch class="switch"
            v-model="crimeSceneFlag"
            :disabled="!edit"
            inactive-text="是否进行现场查勘"></el-switch>
          <div class="surveyorInfo"
            v-if="crimeSceneFlag">
            <span class="label">现场查勘员联系方式</span>
            <el-input v-model="surveyorPhone"
              placeholder="请输入"
              v-if="edit"
              class="input"></el-input>
            <DescriptionsItem v-else
              v-model="surveyorPhone"></DescriptionsItem>
          </div>
        </div>
      </AssociationScrollView>
      <AssociationScrollView title="验标照片"
        name="1"
        v-if="auth('checkSubjectAttachments','e')">
        <div class="tips">
          <span class="required">*</span>远-近，前-后，左-右，上-下，外-内，整体-局部，共拍9~11张照片；
        </div>
        <div class="tips">
          <span class="required">*</span>批量上传时，按照大环境照>整机照>铭牌照>损失照>小时照>补充照片的顺序，依次选择上传的照片。
        </div>
        <PicUpload v-model="picList1"
          class="pic-upload"
          :disabled="!edit"
          :init="detail.category == 1 && detail.orderType == 2 ? 5 : 0"
          @update="picUpdate"
          picSpText
          canDrag></PicUpload>
      </AssociationScrollView>
      <AssociationScrollView title="视频取证"
        name="2"
        v-if="auth('videosAttachments','e')">
        <PicUpload v-model="picList2"
          :disabled="!edit"
          class="pic-upload"></PicUpload>
        <div v-if="!edit && picList2.length === 0">暂无</div>
      </AssociationScrollView>
      <AssociationScrollView title="事故证明"
        name="3"
        v-if="auth('accidentCertificateAttachments','e')">
        <PicUpload v-model="picList3"
          :disabled="!edit"
          class="pic-upload"></PicUpload>
        <div v-if="!edit && picList3.length === 0">暂无</div>
      </AssociationScrollView>
      <AssociationScrollView title="保险利益关系证明"
        name="3"
        v-if="auth('accidentCertificateAttachments','e') && (detail.category == 2 || detail.orderType == 8)">
        <PicUpload v-model="picList5"
          :disabled="!edit"
          class="pic-upload"></PicUpload>
        <div v-if="!edit && picList5.length === 0">暂无</div>
      </AssociationScrollView>
      <AssociationScrollView title="三者案件证明"
        name="4"
        v-if="auth('threeCasesProvedAttachments','e')">
        <div class="tips">1、三者车损：三者车主身份证、行驶证、交通事故责任判定书、赔偿协议，车辆维修报价及发票；</div>
        <div class="tips">2、三者物损：三者身份证、物损维修报价、赔偿协议；</div>
        <div class="tips">3、人伤：事故证明、病历、诊断证明、住院记录、用药清单、发票、收入证明（工资单、银行流水）、伤者身份证、赔偿协议、伤残鉴定书、死亡证明（火化证明、销户证明）。</div>
        <PicUpload v-model="picList4"
          :disabled="!edit"
          class="pic-upload"></PicUpload>
        <div v-if="!edit && picList4.length === 0">暂无</div>
      </AssociationScrollView>
      <AssociationScrollView style="margin-bottom: 90px"
        class="approval"
        title="区域经理审核"
        name="5"
        v-if="auth('regionalManager','e')">
        <el-switch class="switch"
          v-model="managerCheckFlag"
          :disabled="!edit || disabledSwitchAudit || isReOpen"
          inactive-text="是否提交区域经理审核"></el-switch>
        <div v-if="managerCheckFlag">
          <auditProcess :auditList="auditList"
            :negotiationList="negotiationList"></auditProcess>
        </div>
      </AssociationScrollView>
    </AssociationScroll>
  </div>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import PicUpload from "../PicUpload.vue";
import DescriptionsItem from "../items/DescriptionsItem.vue";
import auditProcess from "../auditProcess.vue";
import {
  saveCheckSubject,
  getCheckSubjectDetail,
  getAuditRecord,
  pageNegotiation
} from "@/api/claimData.js";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  name: "LiquidatedDamages",
  components: {
    AssociationScroll,
    AssociationScrollView,
    PicUpload,
    DescriptionsItem,
    auditProcess,
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    edit: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => { },
    },
    processStatus: {
      type: Object,
      default: () => { },
    },
    auditData: {
      type: Object,
      default: () => { },
    },
    isReOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      surveyorPhone: "",
      picList1: [],
      picList2: [],
      picList3: [],
      picList4: [],
      picList5: [],
      crimeSceneFlag: false,
      managerCheckFlag: false,
      curId: "",
      auditList: [],
      oldData: {},
      negotiationList: []
    };
  },
  async created() {
    this.initData();
  },
  watch: {
    auditData() {
      this.initData();
    },
    'detail.category': {
      handler(v) {
         if (!(v == 2 || this.detail.orderType == 8)) {
            this.oldData.picList1 ? this.picList1 = this.oldData.picList1 : this.picList1 = [
               { fileName: "远景-出险环境图", url: "", required: true },
               { fileName: "中景-整机照", url: "", required: true },
               { fileName: "近景-铭牌照", url: "", required: true },
               { fileName: "近景-损失照", url: "", required: true },
               { fileName: "近景-小时数特写照", url: "", required: true },
               ]
         }
      },
      deep: true
    }
  },
  computed: {
    disabledSwitchAudit() {
      if (this.curId) {
        if (this.oldData.managerCheckFlag) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  methods: {
    picUpdate(data) {
      if (this.detail.category == 1 && this.detail.orderType == 2) {
         this.picList1[0].fileName = "远景-出险环境图";
         this.picList1[1].fileName = "中景-整机照";
         this.picList1[2].fileName = "近景-铭牌照";
         this.picList1[3].fileName = "近景-损失照";
         this.picList1[4].fileName = "近景-小时数特写照";
      }
    },
    validate(data) {
      // if (data.crimeSceneFlag && !data.surveyorPhone) return '请填写现场查勘员联系方式';
      if (this.detail.category == 1 && this.detail.orderType == 2) {
         if (!data.checkSubjectAttachments.YBZP[0].attachmentUrl)
         return "请上传远景-出险环境图";
         if (!data.checkSubjectAttachments.YBZP[1].attachmentUrl)
         return "请上传中景-整机图";
         if (!data.checkSubjectAttachments.YBZP[2].attachmentUrl)
         return "请上传近景-铭牌照";
         if (!data.checkSubjectAttachments.YBZP[3].attachmentUrl)
         return "请上传近景-损失照";
         if (!data.checkSubjectAttachments.YBZP[4].attachmentUrl)
         return "请上传近景-小时数特写照";
      }
      return "";
    },
    handleBack() {
      this.$router.go(-1);
    },
    handleSave() {
      let data = {
        caseId: this.id,
        id: this.curId,
        crimeSceneFlag: this.crimeSceneFlag,
        managerCheckFlag: this.managerCheckFlag,
        surveyorPhone: this.crimeSceneFlag ? this.surveyorPhone : "",
        checkSubjectType: "YBZP", //验标附件类型：多个以逗号隔开
        videosType: "SPQZ", //视频取证附件类型：多个以逗号隔开
        accidentCertificateType: "SGZM", //事故证明附件类型：多个以逗号隔开
        threeCasesProvedType: "AJZM", //三者案件证明附件类型：多个以逗号隔开
        interestProveType: "LYGX", //三者案件证明附件类型：多个以逗号隔开
        checkSubjectAttachments: { YBZP: this.picList1 },
        videosAttachments: { SPQZ: this.picList2 },
        interestProveAttachments: { LYGX: this.picList5 },
        accidentCertificateAttachments: { SGZM: this.picList3 },
        threeCasesProvedAttachments: { AJZM: this.picList4 },
      };
      const warn = this.validate(data);
      if (warn) return (this.$message.warning(warn), this.$refs['checkMedia'].$el.scrollIntoView());
      saveCheckSubject(data).then((res) => {
        this.$emit("Submit", true);
        this.$message.success("编辑成功");
      });
    },
    handleWithdraw() {
      return {
        audit: this.managerCheckFlag,
        processId: this.auditData.processId,
      };
    },
    initData() {
      getCheckSubjectDetail(this.id).then(
        (res) => {
          if (res.data.id) {
            for (let index = 0; index < 5; index++) {
              const element = res.data.checkSubjectAttachments.YBZP[index];
              if (element && this.detail.category == 1 && this.detail.orderType == 2) {
                element.required = true;
              }
            }
            this.crimeSceneFlag = res.data.crimeSceneFlag;
            this.managerCheckFlag = res.data.managerCheckFlag;
            this.surveyorPhone = res.data.surveyorPhone;
            this.picList1 = res.data.checkSubjectAttachments.YBZP;
            this.oldData.crimeSceneFlag = res.data.crimeSceneFlag || false;
            this.oldData.managerCheckFlag = res.data.managerCheckFlag;
            this.oldData.surveyorPhone =
              res.data.surveyorPhone || this.surveyorPhone;
            this.oldData.picList1 = res.data.picList1 || this.picList1;
            if (res.data.videosAttachments.SPQZ) {
              this.picList2 = res.data.videosAttachments.SPQZ;
            }
            this.oldData.picList2 =
              res.data.videosAttachments.SPQZ || this.picList2;

            if (res.data.accidentCertificateAttachments.SGZM) {
              this.picList3 = res.data.accidentCertificateAttachments.SGZM;
            }
            this.oldData.picList3 =
              res.data.accidentCertificateAttachments.SGZM || this.picList3;

            if (res.data.interestProveAttachments.LYGX) {
              this.picList5 = res.data.interestProveAttachments.LYGX;
            }
            this.oldData.picList5 =
              res.data.interestProveAttachments.LYGX || this.picList5;

            if (res.data.threeCasesProvedAttachments.AJZM) {
              this.picList4 = res.data.threeCasesProvedAttachments.AJZM;
            }
            this.oldData.picList4 =
              res.data.threeCasesProvedAttachments.AJZM || this.picList4;

            this.curId = res.data.id;
          } else {
            this.oldData.picList4 = this.picList4;
            this.oldData.picList3 = this.picList3;
            this.oldData.picList5 = this.picList5;
            this.oldData.crimeSceneFlag = false;
            this.oldData.managerCheckFlag = false;
            this.oldData.surveyorPhone = this.surveyorPhone;
            this.oldData.picList1 = this.picList1;
            this.oldData.picList2 = this.picList2;
          }
        },
        (err) => {
          this.oldData.picList4 = this.picList4;
          this.oldData.picList3 = this.picList3;
          this.oldData.picList5 = this.picList5;
          this.oldData.crimeSceneFlag = false;
          this.oldData.managerCheckFlag = false;
          this.oldData.surveyorPhone = this.surveyorPhone;
          this.oldData.picList1 = this.picList1;
          this.oldData.picList2 = this.picList2;
        }
      );
      //获取审核记录
      // let auditResult = await getAuditRecord({
      //   approvalType: 1,
      //   caseId: this.id,
      // });
      // this.auditData = auditResult.data;
      if (Object.keys(this.auditData).length) {
        this.auditList = this.auditData.nodes.map((node) => {
          return {
            name: node.approverName,
            type: node.nodeStatusDesc,
            time: node.approveTime ? dayjs(node.approveTime).format("YYYY-MM-DD HH:mm:ss") : '',
            comment: node.nodeOpinion,
          };
        });
      } else {
        this.auditList = [
          {
            // name: this.detail.caseFollower,
            name: this.$store.state.userInfo.name,
            type: "发起审核",
            time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            comment: "",
          },
          //  {
          //    name: this.detail.regionalManager,
          //    type: this.processStatus[this.detail.subjectLossProcessStatus],
          //    time: "",
          //    comment: "",
          //  },
        ];
      }
      pageNegotiation({
        approvalType: 1,
        caseId: this.id,
        size: 999,
      //   processId: this.auditData.processId,
        current: 1,
      }).then(negotiation => {
        if (negotiation.data && negotiation.data.records) {
          this.negotiationList = negotiation.data.records;
        }
      });

    },
    handleReset() {
      Object.keys(this.oldData).forEach((key) => {
        if (
          this.oldData[key] instanceof Array ||
          this.oldData[key] instanceof Object
        ) {
          this[key] = _.cloneDeep(this.oldData[key]);
        } else {
          this[key] = this.oldData[key];
        }
      });
      this.$forceUpdate();
    },
    auth(v, a) {
      if (a == 'e') {
        return dataPermissions('M21-Edit', v);
      } else if (a == 'v') {
        return dataPermissions('M21-View', v);
      } else if (a == 'a') {
        return dataPermissions('M21-Add', v);
      } else {
        return false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.operating-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 250px);
  height: 80px;
  padding: 0 24px;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  z-index: 99;
  position: fixed;
  bottom: 0;
}
::v-deep .el-switch__label {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.select {
  width: 70px;
}
.table-header {
  background: #4278c9ff;
}
.pic-upload {
  margin-top: 24px;
}
.inactive-icon-class {
  font-size: 14px;
}
.lose {
  .item-table {
    .table-main {
      margin-top: 24px;
    }
    .add-item {
      border: 1px solid #dedede;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #00bc0dff;
      font-size: 14px;
      height: 32px;
      .el-icon-circle-plus-outline {
        margin-right: 6px;
      }
    }
  }
  .total-table {
    margin-top: 24px;
    .title-text {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 12px;
      color: #333333;
    }
    .total-all {
      text-align: right;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      margin-top: 12px;
    }
  }
}
.tips {
  margin-top: 10px;
}
.required {
  color: red;
}
.approval {
  .switch {
    margin-top: 24px;
  }
}
.surveyor-content {
  margin-top: 24px;
  display: flex;
  align-items: center;
  .surveyorInfo {
    margin-left: 20px;
    display: flex;
    align-items: center;
    .label {
      width: 140px;
    }
    .input {
      width: 300px;
    }
  }
}
.fix-info-main {
  background: #f7fafdff;
  border-radius: 8px;
  opacity: 1;
  padding: 12px;
  .fix-info {
  }
}
</style>
